import React, { useState, useRef, useEffect } from 'react';
import './Search.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const Search = (props) => {

    const toast = useRef(null);
    const [allDatas, setAllDatas] = useState('');
    const [value, setValue] = useState('');
    const [personalData, setPersonalData] = useState();
    const [sanctionsData, setSanctionsData] = useState();
    const [positionsData, setPositionsData] = useState();
    const [idsData, setIdsData] = useState();
    const [addressData, setAddressData] = useState();
    const [expandedRows, setExpandedRows] = useState(null);
    const [pageCount, setPageCount] = useState('');
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState(['']);


    const [type_I, settype_I] = useState('');
    const [idNumber_I, setidNumber_I] = useState('');
    const [summary_I, setsummary_I] = useState('');
    const [country_I, setcountry_I] = useState('');
    const [startDate_I, setstartDate_I] = useState('');
    const [endDate_I, setendDate_I] = useState('');

    const [positionsV, setPositionsV] = useState('');
    const [countryV, setCountryV] = useState('');

    const [fullAddress_A, setfullAddress_A] = useState('');
    const [remarks_A, setremarks_A] = useState('');
    const [postOfficeBox_A, setpostOfficeBox_A] = useState('');
    const [street_A, setstreet_A] = useState('');
    const [city_A, setcity_A] = useState('');
    const [postalCode_A, setpostalCode_A] = useState('');
    const [region_A, setregion_A] = useState('');
    const [state_A, setstate_A] = useState('');
    const [country_A, setcountry_A] = useState('');

    const [fieldName, setFieldName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [data, setData] = useState(['']);
    const [allData, setAlldata] = useState(['']);

    const [authority_San, setAuthority_San] = useState('');
    const [authorityId_San, setAuthorityId_San] = useState('');
    const [startDate_San, setStartDate_San] = useState('');
    const [endDate_San, setEndDate_San] = useState('');
    const [country_San, setCountry_San] = useState('');
    const [listingDate_San, setListingDate_San] = useState('');
    const [modifiedAt_San, setModifiedAt_San] = useState('');
    const [program_San, setProgram_San] = useState('');
    const [provisions_San, setProvisions_San] = useState('');
    const [reason_San, setReason_San] = useState('');
    const [status_San, setStatus_San] = useState('');
    const [retrievedAt_San, setRetrievedAt_San] = useState('');
    const [description_San, setDescription_San] = useState('');
    const [sourceUrl_San, setSourceUrl_San] = useState('');
    const [recordId_San, setRecordId_San] = useState('');
    const [summary_San, setSummary_San] = useState('');
    const [unscId_San, setUnscId_San] = useState('');
    const token = props?.location?.state?.token;
    const history = useHistory();

    useEffect(() => {
        if (!token) {
            setTimeout(() => {
                history.push({ pathname: '/' })
            }, 2000);
        }
    }, [token]);


    const load = () => {
        if (value.length >= 4) {
            axios.get('https://demo.acaia.us/person_peps/' + value + '?token=' + token)
                .then(response => {
                    setAllDatas(response?.data);
                    setPersonalData(response?.data?.personalDetails);
                    setSanctionsData(response?.data?.sanctions);
                    setPositionsData(response?.data?.positions);
                    setIdsData(response?.data?.ids);
                    setAddressData(response?.data?.address);
                    setPageCount('');
                    toast.current.show({ severity: 'success', summary: 'Success', life: 3000 });
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                });
        }
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const addInputBox = (event) => {
        const payload = {
            [fieldName]: fieldValue
        }
        const mergObject = { ...payload, ...data };
        const { '0': _, ...payloadData } = mergObject;
        setData(payloadData)
        setFieldName('');
        setFieldValue('');
    };



    const handleSubmit = () => {
        // const newValues = [...inputValues, ''];

        if (pageCount === 'Positions') {

            const payload = {
                position: positionsV || visible?.position,
                country: countryV || visible?.country
            }

            axios.put('https://demo.acaia.us/post/' + visible?.postId + '?token=' + token, payload)
                .then(response => {
                    setVisible('');
                    load();
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                    setCountryV('');
                    setPositionsV('');
                });
        } else if (pageCount === 'Ids') {

            const payload = {
                type: type_I || visible?.type,
                idNumber: idNumber_I || visible?.idNumber,
                summary: summary_I || visible?.summary,
                country: country_I || visible?.country,
                startDate: startDate_I || visible?.startDate,
                endDate: endDate_I || visible?.endDate,
            }

            axios.put('https://demo.acaia.us/identification/' + visible?.identificationId + '?token=' + token, payload)
                .then(response => {
                    setVisible('');
                    load();
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                    setCountryV('');
                    setPositionsV('');
                });
        } else if (pageCount === 'Address') {

            const payload = {
                fullAddress: fullAddress_A || visible?.fullAddress,
                remarks: remarks_A || visible?.remarks,
                postOfficeBox: postOfficeBox_A || visible?.postOfficeBox,
                street: street_A || visible?.street,
                city: city_A || visible?.city,
                postalCode: postalCode_A || visible?.postalCode,
                region: region_A || visible?.region,
                state: state_A || visible?.state,
                country: country_A || visible?.country,
            }

            axios.put('https://demo.acaia.us/address/' + visible?.addressId + '?token=' + token, payload)
                .then(response => {
                    setVisible('');
                    setTimeout(load(), 5000);
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                    setCountryV('');
                    setPositionsV('');
                });
        } else if (pageCount === 'Personal Details') {

            const payload = {
                [fieldName]: fieldValue
            }

            const mergObject = { ...payload, ...data };
            const { '0': _, ...payloadData } = mergObject;

            const p_Load = {
                "properties": payloadData
            }

            axios.post('https://demo.acaia.us/' + value + '/updateProperty' + '?token=' + token, p_Load)
                .then(response => {
                    setVisible('');
                    setFieldName('');
                    setFieldValue('');
                    setTimeout(load(), 5000);
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                    setFieldName('');
                    setFieldValue('');
                });

        } else if (pageCount === 'Sanctions') {

            const payload = {
                number: visible?.number,
                authority: authority_San || visible?.authority,
                authorityId: authorityId_San || visible?.authorityId,
                startDate: startDate_San || visible?.startDate,
                endDate: endDate_San || visible?.endDate,
                country: country_San || visible?.country,
                listingDate: listingDate_San || visible?.listingDate,
                modifiedAt: modifiedAt_San || visible?.modifiedAt,
                program: program_San || visible?.program,
                provisions: provisions_San || visible?.provisions,
                reason: reason_San || visible?.reason,
                status: status_San || visible?.status,
                retrievedAt: retrievedAt_San || visible?.retrievedAt,
                description: description_San || visible?.description,
                sourceUrl: sourceUrl_San || visible?.sourceUrl,
                recordId: recordId_San || visible?.recordId,
                summary: summary_San || visible?.summary,
                unscId: unscId_San || visible?.unscId,
            }

            axios.put('https://demo.acaia.us/' + visible?.number + '?token=' + token, payload)
                .then(response => {
                    setVisible('');
                    setTimeout(load(), 5000);
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                    setCountryV('');
                    setPositionsV('');
                });
        }
    };

    const getAllData = (e) => {
        setPageCount(e?.target?.innerText)
    }

    const updateDialogBox = (e) => {
        setVisible(true)
    }


    return (
        <>
            {token &&
                <>
                    <Navbar activeData='Search Data' token={token} />
                    <div className='search_Sec'>
                        <Toast ref={toast} />

                        <div className='t_View'>
                            <InputText value={value} style={{ width: '50%' }} placeholder='Search' onChange={(e) => setValue(e.target.value)} />
                            <Button label="Submit" className='expandTemplate_Btn act_Sub' onClick={load} />
                        </div>

                        <div className='jSON'>
                            {
                                Object.keys(allDatas).map((key) => (
                                    <li key={key}><strong>{key}:</strong>{JSON.stringify(allDatas[key])}</li>
                                ))
                            }
                        </div>

                        {allDatas ?
                            <div className='expandTemplate'>
                                <Button label='Personal Details' className={pageCount === 'Personal Details' ? 'expandTemplate_Btn  act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                                <Button label='Positions' className={pageCount === 'Positions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                                <Button label='Ids' className={pageCount === 'Ids' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                                <Button label='Address' className={pageCount === 'Address' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                                <Button label='Sanctions' className={pageCount === 'Sanctions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                            </div>
                            : ''}

                        {pageCount === 'Personal Details' ? (
                            <div className='ce_P'>
                                {pageCount ?
                                    <strong>{'Total Count : ' + allDatas?.personalDetails?.length}</strong>
                                    : ''}
                                {personalData?.map((item, index) => (
                                    <>
                                        <div className='align'>
                                            <strong>{'Count : ' + index}</strong>
                                            <Button label='Update' className='expandTemplate_Btn' onClick={(e) => setVisible(item)} />
                                        </div>
                                        {Object.keys(item).map((key) => (
                                            <li key={key} style={{ listStyleType: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0 25px' }}>
                                                    <div style={{ width: '150px' }}><strong>{key}</strong></div>
                                                    {":"}
                                                    <InputText value={item[key]} />
                                                </div>
                                            </li >
                                        ))}
                                    </>
                                ))}

                                {allDatas?.personalDetails?.length < 1 ? (
                                    <>
                                        <div className='no_Data'>No Data in {pageCount}</div>
                                    </>
                                ) : ''}

                                <Dialog header={pageCount + ' Update'} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!pageCount) return; setVisible(false); }}>
                                    <div className='hei'>
                                        {personalData?.map((item, index) => (
                                            <>
                                                {Object.keys(item).map((key) => (
                                                    <>
                                                        <li key={key} className='lI'>
                                                            <strong style={{ width: '150px' }}>{key}</strong>
                                                            {":"}
                                                            <InputText value={item[key]} />
                                                        </li>
                                                    </>
                                                ))}
                                            </>
                                        ))}
                                    </div>



                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>

                                        <h2>Edit</h2>
                                        {Object.keys(allData).map((key) => (
                                            <>
                                                <li className='perD' style={{ display: key === '0' ? 'none' : 'flex' }} key={key}>
                                                    <strong style={{ width: '150px' }}>{key}</strong>
                                                    {":"}
                                                    <InputText value={allData[key]} />
                                                </li>
                                            </>
                                        ))}

                                        <div style={{ display: 'flex', width: '100%', gap: '25px' }}>
                                            <div style={{ width: '100%' }}>
                                                <label>Property Name</label>
                                                <InputText
                                                    type="text"
                                                    value={fieldName}
                                                    onChange={(e) => setFieldName(e.target.value)}
                                                />
                                            </div>

                                            <div style={{ width: '100%' }}>
                                                <label>Property Value</label>
                                                <InputText
                                                    type="text"
                                                    value={fieldValue}
                                                    onChange={(e) => setFieldValue(e.target.value)}
                                                    disabled={!fieldName}
                                                />
                                            </div>

                                        </div>

                                        <div style={{ display: 'flex', gap: '25px' }}>
                                            <Button onClick={addInputBox} disabled={!fieldName || !fieldValue} className='expandTemplate_Btn act_Sub' label='Add' />
                                            <Button onClick={handleSubmit} disabled={!fieldName || !fieldValue} className='expandTemplate_Btn act_Sub' label='Continue' />
                                        </div>
                                    </div>
                                </Dialog>

                            </div>
                        ) : pageCount === 'Sanctions' ? (
                            <div className='ce_P'>
                                {pageCount ?
                                    <strong>{'Total Count : ' + allDatas?.sanctions?.length}</strong>
                                    : ''}
                                {allDatas?.sanctions?.map((item, index) => (
                                    <>
                                        <div className='align'>
                                            <strong>{'Count : ' + index}</strong>
                                            <Button label='Update' className='expandTemplate_Btn' onClick={(e) => setVisible(item)} />
                                        </div>
                                        {Object.keys(item).map((key) => (
                                            <li key={key} style={{ listStyleType: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0 25px' }}>
                                                    <div style={{ width: '150px' }}><strong>{key}</strong></div>
                                                    {":"}
                                                    <InputText value={item[key]} />
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ))}

                                {allDatas?.sanctions?.length < 1 ? (
                                    <>
                                        <div className='no_Data'>No Data in {pageCount}</div>
                                    </>
                                ) : ''}

                                <Dialog header={pageCount + ' Update'} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!pageCount) return; setVisible(false); }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Number</strong>
                                            <InputText value={fullAddress_A || visible?.number} disabled />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Authority</strong>
                                            <InputText value={authority_San || visible?.authority} onChange={(e) => setAuthority_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Authority Id</strong>
                                            <InputText value={authorityId_San || visible?.authorityId} onChange={(e) => setAuthorityId_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Start Date</strong>
                                            <InputText value={startDate_San || visible?.startDate} onChange={(e) => setStartDate_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>End Date</strong>
                                            <InputText value={endDate_San || visible?.endDate} onChange={(e) => setEndDate_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Country</strong>
                                            <InputText value={country_San || visible?.country} onChange={(e) => setCountry_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Listing Date</strong>
                                            <InputText value={listingDate_San || visible?.listingDate} onChange={(e) => setListingDate_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Modified At</strong>
                                            <InputText value={modifiedAt_San || visible?.modifiedAt} onChange={(e) => setModifiedAt_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Program</strong>
                                            <InputText value={program_San || visible?.program} onChange={(e) => setProgram_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Provisions</strong>
                                            <InputText value={provisions_San || visible?.provisions} onChange={(e) => setProvisions_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Reason</strong>
                                            <InputText value={reason_San || visible?.reason} onChange={(e) => setReason_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Status</strong>
                                            <InputText value={status_San || visible?.status} onChange={(e) => setStatus_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Retrieved At</strong>
                                            <InputText value={retrievedAt_San || visible?.retrievedAt} onChange={(e) => setRetrievedAt_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Description</strong>
                                            <InputText value={description_San || visible?.description} onChange={(e) => setDescription_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>SourceUrl</strong>
                                            <InputText value={sourceUrl_San || visible?.sourceUrl} onChange={(e) => setSourceUrl_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Record Id</strong>
                                            <InputText value={recordId_San || visible?.recordId} onChange={(e) => setRecordId_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Summary</strong>
                                            <InputText value={summary_San || visible?.summary} onChange={(e) => setSummary_San(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Unsc Id</strong>
                                            <InputText value={unscId_San || visible?.unscId} onChange={(e) => setUnscId_San(e.target.value)} />
                                        </div>

                                        <div style={{ display: 'flex', gap: '25px' }}>
                                            <Button onClick={handleSubmit} className='expandTemplate_Btn act_Sub' label='Continue' />
                                        </div>

                                    </div>

                                </Dialog>
                            </div>
                        ) : pageCount === 'Positions' ? (
                            <div className='ce_P'>
                                {pageCount ?
                                    <strong>{'Total Count : ' + allDatas?.positions?.length}</strong>
                                    : ''}
                                {allDatas?.positions?.map((item, index) => (
                                    <>
                                        <div className='align'>
                                            <strong>{'Count : ' + index}</strong>
                                            <Button label='Update' className='expandTemplate_Btn' onClick={(e) => setVisible(item)} />
                                        </div>
                                        {Object.keys(item).map((key) => (
                                            <li key={key} style={{ listStyleType: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0 25px' }}>
                                                    <div style={{ width: '150px' }}><strong>{key}</strong></div>
                                                    {":"}
                                                    <InputText value={item[key]} />
                                                </div>
                                            </li>
                                        ))}

                                    </>
                                ))}

                                {allDatas?.positions?.length < 1 ? (
                                    <>
                                        <div className='no_Data'>No Data in {pageCount}</div>
                                    </>
                                ) : ''}

                                <Dialog header={pageCount + ' Update'} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!pageCount) return; setVisible(false); }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Position</strong>
                                            <InputText value={positionsV || visible?.position} onChange={(e) => setPositionsV(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Country</strong>
                                            <InputText value={countryV || visible?.country} onChange={(e) => setCountryV(e.target.value)} />
                                        </div>



                                        <div style={{ display: 'flex', gap: '25px' }}>
                                            <Button onClick={handleSubmit} className='expandTemplate_Btn act_Sub' label='Continue' />
                                        </div>

                                    </div>
                                </Dialog>
                            </div>
                        ) : pageCount === 'Ids' ? (
                            <div className='ce_P'>
                                {pageCount ?
                                    <strong>{'Total Count : ' + allDatas?.ids?.length}</strong>
                                    : ''}
                                {allDatas?.ids?.map((item, index) => (
                                    <>
                                        <div className='align'>
                                            <strong>{'Count : ' + index}</strong>
                                            <Button label='Update' className='expandTemplate_Btn' onClick={(e) => setVisible(item)} />
                                        </div>
                                        {Object.keys(item).map((key) => (
                                            <li key={key} style={{ listStyleType: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0 25px' }}>
                                                    <div style={{ width: '150px' }}><strong>{key}</strong></div>
                                                    {":"}
                                                    <InputText value={item[key]} />
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ))}

                                {allDatas?.ids?.length < 1 ? (
                                    <>
                                        <div className='no_Data'>No Data in {pageCount}</div>
                                    </>
                                ) : ''}

                                <Dialog header={pageCount + ' Update'} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!pageCount) return; setVisible(false); }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Type</strong>
                                            <InputText value={type_I || visible?.type} onChange={(e) => settype_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Id Number</strong>
                                            <InputText value={idNumber_I || visible?.idNumber} onChange={(e) => setidNumber_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Summary</strong>
                                            <InputText value={summary_I || visible?.summary} onChange={(e) => setsummary_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Country</strong>
                                            <InputText value={country_I || visible?.country} onChange={(e) => setcountry_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Start Date</strong>
                                            <InputText value={startDate_I || visible?.startDate} onChange={(e) => setstartDate_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>End Date</strong>
                                            <InputText value={endDate_I || visible?.endDate} onChange={(e) => setendDate_I(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Identification Id</strong>
                                            <InputText disabled value={visible?.identificationId} />
                                        </div>

                                        <div style={{ display: 'flex', gap: '25px' }}>
                                            <Button onClick={handleSubmit} className='expandTemplate_Btn act_Sub' label='Continue' />
                                        </div>

                                    </div>
                                </Dialog>
                            </div>
                        ) : pageCount === 'Address' ? (
                            <div className='ce_P'>
                                {pageCount ?
                                    <strong>{'Total Count : ' + allDatas?.address?.length}</strong>
                                    : ''}
                                {allDatas?.address?.map((item, index) => (
                                    <>
                                        <div className='align'>
                                            <strong>{'Count : ' + index}</strong>
                                            <Button label='Update' className='expandTemplate_Btn' onClick={(e) => setVisible(item)} />
                                        </div>
                                        {Object.keys(item).map((key) => (
                                            <li key={key} style={{ listStyleType: 'auto' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', padding: '0 25px' }}>
                                                    <div style={{ width: '150px' }}><strong>{key}</strong></div>
                                                    {":"}
                                                    <InputText value={item[key]} />
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ))}

                                {allDatas?.address?.length < 1 ? (
                                    <>
                                        <div className='no_Data'>No Data in {pageCount}</div>
                                    </>
                                ) : ''}

                                <Dialog header={pageCount + ' Update'} visible={visible} style={{ width: '50vw' }} onHide={() => { if (!pageCount) return; setVisible(false); }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Full Address</strong>
                                            <InputText value={fullAddress_A || visible?.fullAddress} onChange={(e) => setfullAddress_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Remarks</strong>
                                            <InputText value={remarks_A || visible?.region} onChange={(e) => setremarks_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Post Office Box</strong>
                                            <InputText value={postOfficeBox_A || visible?.postOfficeBox} onChange={(e) => setpostOfficeBox_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Street</strong>
                                            <InputText value={street_A || visible?.street} onChange={(e) => setstreet_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>City</strong>
                                            <InputText value={city_A || visible?.city} onChange={(e) => setcity_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Postal Code</strong>
                                            <InputText value={postalCode_A || visible?.postalCode} onChange={(e) => setpostalCode_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Region</strong>
                                            <InputText value={region_A || visible?.region} onChange={(e) => setregion_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>State</strong>
                                            <InputText value={state_A || visible?.state} onChange={(e) => setstate_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Country</strong>
                                            <InputText value={country_A || visible?.country} onChange={(e) => setcountry_A(e.target.value)} />
                                        </div>

                                        <div className='inPUT'>
                                            <strong className='leaBLE'>Address Id</strong>
                                            <InputText value={visible?.addressId} disabled />
                                        </div>



                                        <div style={{ display: 'flex', gap: '25px' }}>
                                            <Button onClick={handleSubmit} className='expandTemplate_Btn act_Sub' label='Continue' />
                                        </div>

                                    </div>
                                </Dialog>

                            </div>
                        ) : ''}
                    </div >
                </>
            }
        </>
    );
};

export default Search;
