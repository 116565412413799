import React, { useState, useRef, useEffect } from 'react';
import './Login.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CryptoJS from 'crypto-js';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Login = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const history = useHistory();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setUserName(value);
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9!@#$%^&*()_+=\-[\]{}|\\;:'",.<>/?]*$/.test(value)) {
            setPassword(value);
        }
    };

    const load = () => {
        setLoading(true);
        axios.post('https://demo.acaia.us/' + 'login?email=' + userName + '&password=' + password)
            .then(response => {
                setTimeout(() => {
                    setLoading(false);
                    history.push({
                        pathname: '/automation',
                        state: { 'token': response.data }
                    });
                }, 2000);
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Passwords do not match', life: 3000 });
                setLoading(false)
            });
    };

    return (
        <>
            <Toast ref={toast} />

            <div className='login_Sec'>
                <div className='section'>
                    <div className='sS'>{windowWidth < 320 ? 'A-CQ' : 'ACAIA-CQ'}</div>
                    <div className="box">
                        <span> User Name</span>
                        <InputText value={userName} onChange={handleInputChange} />
                    </div>

                    <div className="box">
                        <span> Password</span>
                        <InputText type="password" value={password} onChange={handlePasswordChange} />
                    </div>

                    <Button disabled={!userName || !password} label="Submit" icon="pi pi-check" style={{ padding: '0 20px' }} className='expandTemplate_Btn act_Sub' loading={loading} onClick={load} />
                </div>
            </div>
        </>
    );
};

export default Login;
