import React, { useState, useEffect, useRef } from 'react';
import './Final.css';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const Final = (props) => {

    const toast = useRef(null);
    const token = props?.location?.state?.token;
    const [allDatas, setAllDatas] = useState('');
    const [triggerButton, setTriggerButton] = useState(false);
    const [value, setValue] = useState('');
    const [value2, setValue2] = useState('');
    const [expandedRows, setExpandedRows] = useState(null);
    const [pageCount, setPageCount] = useState('');
    const history = useHistory();

    const footer = `Total Count :  ${allDatas?.individuals ? allDatas?.individuals?.length : 0}`;

    useEffect(() => {
        if (!token) {
            setTimeout(() => {
                history.push({ pathname: '/' })
            }, 2000);
        }
    }, [token]);

    const items = [
        { name: 'Exact', value: 1 },
        { name: 'Partial', value: 2 },
        { name: 'Wrinkler Distance', value: 3 }
    ];

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const getAllData = (e) => {
        setPageCount(e?.target?.innerText)
    }

    const handleClick = (item) => {
        const name = item.value;
        setValue2(name);

        const payload = {
            fullName: value,
            exact: value2 === 1 ? 'true' : 'false',
            partial: value2 === 2 ? 'true' : 'false',
            wrinklenDistance: value2 === 3 ? 'true' : 'false',
        }

        axios.post('https://demo.acaia.us/data', payload)

            .then(response => {
                setAllDatas(response?.data);
                setValue('');
                toast.current.show({ severity: 'success', summary: 'Success', life: 3000 });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
            });

    };

    const expandTemplate = (rowData) => (
        <>
            <div className='expandTemplate'>
                <Button label='Personal Details' className={pageCount === 'Personal Details' ? 'expandTemplate_Btn  act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Sanctions' className={pageCount === 'Sanctions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Positions' className={pageCount === 'Positions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Ids' className={pageCount === 'Ids' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Address' className={pageCount === 'Address' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
            </div>

            {pageCount === 'Personal Details' ? (
                <div className='ce_P'>
                    {rowData?.personalDetails?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.personalDetails?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Sanctions' ? (
                <div className='ce_P'>
                    {rowData?.sanctions?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.sanctions?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Positions' ? (
                <div className='ce_P'>
                    {rowData?.positions?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.positions?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Ids' ? (
                <div className='ce_P'>
                    {rowData?.ids?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.ids?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Address' ? (
                <div className='ce_P'>
                    {rowData?.address?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.address?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : ''}
        </>
    );

    return (
        <>
            {token &&
                <>
                    <Navbar activeData='Final Data' token={token} />
                    <div className='final_Sec'>
                        <Toast ref={toast} />

                        <div className='t_View asd'>
                            <InputText value={value} style={{ width: '50%' }} placeholder='Search' onChange={(e) => setValue(e.target.value)} />
                            <div>
                                {items.map((item, index) => (
                                    <Button label={item?.name} className={item.value === value2 ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => setValue2(item?.value)} />
                                ))}
                            </div>
                            <Button label="Submit" disabled={!value || !value2} className='expandTemplate_Btn act_Sub' onClick={handleClick} />
                        </div>

                        {allDatas ?
                            <div style={{ padding: '20px' }}>
                                <h2>Final Data</h2>
                                <DataTable
                                    stripedRows
                                    showGridlines
                                    paginator
                                    rows={8}
                                    rowsPerPageOptions={[250, 500, 750, 1000]}
                                    footer={footer}
                                    value={allDatas?.individuals}
                                    rowExpansionTemplate={expandTemplate}
                                    expandedRows={expandedRows} onRowToggle={onRowToggle}
                                >
                                    <Column field="id" header="Id"></Column>
                                    <Column field="fullName" header="Name"></Column>
                                    <Column field="number" header="Number"></Column>
                                    <Column field="action" header="Action"></Column>
                                    <Column expander style={{ width: '3em' }} header="Info" />
                                </DataTable>
                            </div>
                            : ''}
                    </div>
                </>
            }
        </>
    );
};

export default Final;
