import React, { useEffect } from 'react';
import './App.css';
import Login from "./Main/Login/Login";
import Navbar from "./Main/Navbar/Navbar";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Modified from './Main/Modified/Modified';
import Search from './Main/Search/Search';
import Final from './Main/Final/Final';
import Automation from './Main/Automation/Automation';

function App() {

  // useEffect(() => {
  //   document.addEventListener('contextmenu', (event) => {
  //     event.preventDefault();
  //   });

  //   document.addEventListener('keydown', (event) => {
  //     if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'C')) {
  //       event.preventDefault();
  //     }
  //   });
  // }, []);

  return (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/automation" component={Automation} />
            <Route path="/modified" component={Modified} />
            <Route path="/search" component={Search} />
            <Route path="/final" component={Final} />
          </Switch>
        </div>
      </Router>
    </>
  );
}

export default App;
