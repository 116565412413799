import React, { useState, useEffect, useRef } from 'react';
import './Automation.css';
import { Button } from 'primereact/button';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const Automation = (props) => {

    const history = useHistory();
    const token = props?.location?.state?.token;
    const toast = useRef(null);
    const [allDatas, setAllDatas] = useState('');
    const [expandedRows, setExpandedRows] = useState(null);
    const [pageCount, setPageCount] = useState('');
    const [personalData, setPersonalData] = useState();
    const [sanctionsData, setSanctionsData] = useState();
    const [positionsData, setPositionsData] = useState();
    const [idsData, setIdsData] = useState();
    const [addressData, setAddressData] = useState();
    const [triggerButton, setTriggerButton] = useState(false);

    useEffect(() => {
        if (token) {
            setTriggerButton(true);
        } else {
            setTimeout(() => {
                history.push({ pathname: '/' })
            }, 2000);
        }
    }, [token]);

    useEffect(() => {
        if (triggerButton) {
            getAllData();
            setTriggerButton(false);
        }
    }, [triggerButton]);

    const getAllData = (e) => {
        setPageCount(e?.target?.innerText)

        if (!allDatas || !personalData || !sanctionsData || !positionsData || !idsData || !addressData) {
            axios.get('https://demo.acaia.us/person_peps?page=0&size=10&token=' + token)
                .then(response => {
                    toast.current.show({ severity: 'success', summary: 'Success', life: 3000 });
                    setAllDatas(response?.data);
                    {
                        response?.data?.content?.map((item) => (
                            setPersonalData(item?.personalDetails),
                            setSanctionsData(item?.sanctions),
                            setPositionsData(item?.positions),
                            setIdsData(item?.ids),
                            setAddressData(item?.address)
                        ))
                    }
                })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
                });
        }
    };

    const footer = `Total Count :  ${allDatas?.content ? allDatas?.content?.length : 0}`;

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const [visible, setVisible] = useState(false);

    const sanctionsUpdate = (e) => {
        setVisible(true)
    }

    const handleInputChange = (itemId, e) => {
        // sanctionsData
        const updatedItems = sanctionsData.map(item =>
            itemId ? { ...item, inputValue: e.target.value } : item
        );
        // setItems(updatedItems);
    };

    const expandTemplate = (rowData) => (
        <>
            <div className='expandTemplate'>
                <Button label='Personal Details' className={pageCount === 'Personal Details' ? 'expandTemplate_Btn  act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Sanctions' className={pageCount === 'Sanctions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Positions' className={pageCount === 'Positions' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Ids' className={pageCount === 'Ids' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
                <Button label='Address' className={pageCount === 'Address' ? 'expandTemplate_Btn act_Sub' : 'expandTemplate_Btn'} onClick={(e) => getAllData(e)} />
            </div>

            {pageCount === 'Personal Details' ? (
                <div className='ce_P'>
                    {rowData?.personalDetails?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.personalDetails?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Sanctions' ? (
                <div className='ce_P'>
                    {rowData?.sanctions?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.sanctions?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Positions' ? (
                <div className='ce_P'>
                    {rowData?.positions?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.positions?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Ids' ? (
                <div className='ce_P'>
                    {rowData?.ids?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.ids?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : pageCount === 'Address' ? (
                <div className='ce_P'>
                    {rowData?.address?.map((item, index) => (
                        <>
                            {Object.keys(item).map((key) => (
                                <li key={key} style={{ listStyleType: 'auto' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 25px' }}>
                                        <div style={{ width: '150px' }}><strong>{key}:</strong></div>
                                        <InputText value={JSON.stringify(item[key])} />
                                    </div>
                                </li>
                            ))}
                        </>
                    ))}

                    {rowData?.address?.length < 1 ? (
                        <>
                            <div className='no_Data'>No Data in {pageCount}</div>
                        </>
                    ) : ''}
                </div>
            ) : ''}
        </>
    );

    return (
        <>
            {token &&
                <>
                    <Navbar activeData='Automation Data' token={token} />
                    <div className='automation_Sec'>
                        <Toast ref={toast} />

                        <div style={{ padding: '20px' }}>
                            <h2>Automation Data</h2>
                            <DataTable
                                stripedRows
                                showGridlines
                                paginator
                                rows={8}
                                rowsPerPageOptions={[250, 500, 750, 1000]}
                                footer={footer} value={allDatas?.content}
                                rowExpansionTemplate={expandTemplate}
                                expandedRows={expandedRows} onRowToggle={onRowToggle}
                            >
                                <Column expander style={{ width: '3em' }} />
                                <Column field="id" header="Id"></Column>
                                <Column field="fullName" header="Full Name"></Column>
                                <Column field="number" header="Number"></Column>
                            </DataTable>
                        </div>
                    </div>

                </>
            }
        </>
    );
};

export default Automation;
