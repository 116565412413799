import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

const Navbar = (props) => {

    const [pageCount, setPageCount] = useState(props?.activeData);
    const history = useHistory();

    const items = [
        { name: 'Automation Data', path: "/automation" },
        { name: 'Modified Data', path: "/modified" },
        { name: 'Search Data', path: "/search" },
        { name: 'Final Data', path: "/final" },
    ];

    const navigate = (navigateData) => {
        history.push({ pathname: navigateData, state: { 'token': props?.token } })
    }

    return (
        <>
            <div className='navbar_Sec'>
                <div className='navbar_Sec_T'>
                    <div className='heading'>
                        {'Acaia'}< br /> {'Sanction-CQ'}
                    </div>

                    <div style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                        {items.map((item, index) => (
                            <Button label={item?.name} className={item?.name === pageCount ? 'btN act' : 'btN'} onClick={(e) => navigate(item?.path)} />
                        ))}
                        <i style={{ cursor: 'pointer' }} label='Logout' className='pi pi-sign-in' onClick={(e) => history.push({ pathname: '/', state: '' })} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
