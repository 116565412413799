import React, { useState, useEffect, useRef } from 'react';
import './Modified.css';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import Navbar from '../Navbar/Navbar';
import { useHistory } from 'react-router-dom';

const Modified = (props) => {

    const [allDatas, setAllDatas] = useState('');
    const [triggerButton, setTriggerButton] = useState(false);
    const token = props?.location?.state?.token;
    const toast = useRef(null);
    const history = useHistory();

    useEffect(() => {
        if (token) {
            setTriggerButton(true);
        } else {
            setTimeout(() => {
                history.push({ pathname: '/' })
            }, 2000);
        }
    }, [token]);


    useEffect(() => {
        if (triggerButton) {
            handleClick();
            setTriggerButton(false);
        }
    }, [triggerButton]);

    const handleClick = () => {
        axios.get('https://demo.acaia.us/difference_records?token=' + token)
            .then(response => {
                toast.current.show({ severity: 'success', summary: 'Success', life: 3000 });
                setAllDatas(response?.data);
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', life: 3000 });
            });
    };

    const footer = `Total Count :  ${allDatas?.content ? allDatas?.content?.length : 0}`;

    return (
        <>
            {token &&
                <>
                    <Navbar activeData='Modified Data' token={token} />
                    <div className='modified_Sec'>
                        <Toast ref={toast} />

                        <div style={{ padding: '20px' }}>
                            <h2>Modified Data</h2>
                            <DataTable
                                stripedRows
                                showGridlines
                                paginator
                                rows={8}
                                rowsPerPageOptions={[250, 500, 750, 1000]}
                                footer={footer}
                                value={allDatas?.content}
                            >
                                <Column field="id" header="Id"></Column>
                                <Column field="name" header="Name"></Column>
                                <Column field="number" header="Number"></Column>
                                <Column field="newData" header="New Data"></Column>
                                <Column field="currentDate" header="Current Date"></Column>
                                <Column field="existingData" header="Existing Data"></Column>
                            </DataTable>
                        </div>
                    </div>
                </>
            }
        </>
    );

};

export default Modified;
